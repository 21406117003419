@import './Common.css';

.templates {
  padding: 20px;
  flex: 1;
  margin-left: 260px; /* Increase margin to give more space between the sidebar and content */
  margin-top: 70px; /* Ensure space for header */
  transition: margin-left 0.3s ease;
}


.ai-template-library {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.popular-templates {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Keep 3 columns */
  gap: 20px;
  padding-left: 20px; /* Add padding inside the content */
  padding-right: 20px;
  justify-items: center;
  max-width: 1200px;
  margin: 40px auto;
  padding-bottom: 40px;
}



.view-template-container {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #20232a;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.view-template-container h1 {
  font-size: 28px;
  margin-bottom: 10px;
}

.view-template-container .template-category {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}

.view-template-description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.view-template-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.view-template-copy-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 12px 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.view-template-copy-button:hover {
  background-color: #2980b9;
  box-shadow: 0 0 15px rgba(52, 152, 219, 0.75);
}

.view-template-back-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-left: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.view-template-back-button:hover {
  background-color: #c0392b;
  box-shadow: 0 0 15px rgba(231, 76, 60, 0.75);
}

/* Responsive adjustments for smaller screens */
@media screen and (max-width: 1200px) {
  .popular-templates {
    grid-template-columns: repeat(2, 1fr); /* 2 templates per row on smaller screens */
  }
}

@media screen and (max-width: 768px) {
  .popular-templates {
    grid-template-columns: 1fr; /* 1 template per row on very small screens */
  }
}






