@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap'); /* New font family for better readability */

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #1a1a1a;
  color: #ffffff;
  overflow-x: hidden;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Lato', sans-serif; /* Using Lato for a clean and modern look */
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #282c34;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.header-left {
  display: flex;
  align-items: center;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
}

.menu-icon {
  width: 24px;
  height: auto;
}

.header-center {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 15px;
}

.library-title {
  font-size: 28px;
  font-weight: bold;
  font-family: 'Lato', sans-serif; /* Ensure consistency with the main font */
}

.header-right {
  display: flex;
  align-items: center;
}

.greeting {
  margin-right: 10px;
  font-size: 16px;
}

.logout-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.logout-button:hover {
  background-color: #c0392b;
  box-shadow: 0 0 15px rgba(231, 76, 60, 0.75);
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;
  object-fit: cover;
}

/* Category filters */
.category-filters {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  flex-wrap: wrap;
}

.category-filter {
  background: #333;
  color: white;
  padding: 10px 20px;
  margin: 0 10px 10px 0;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.category-filter.active,
.category-filter:hover {
  background: #666;
}

/* Search section */
.search-section {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 50px;
  padding: 5px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 300px;
}

.search-bar input {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 50px;
  border: none;
  outline: none;
  background: transparent;
  color: #000;
  width: 100%;
  font-family: 'Lato', sans-serif;
}

.search-icon {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

/* Prompt and Template Box Styling */
/* Prompt and Template Box Styling */
.prompt, .template {
    border-radius: 10px;
    padding: 35px;
    width: 100%;
    text-align: left;
    background: #20232a;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    font-family: 'Lato', sans-serif;
    height: 350px; /* Uniform height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 350px; /* Optional: Set a max width to control box size */
}





/* Ensure the prompt and template titles are also consistent */
.prompt h3, .template h3 {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  color: #ffffff;
  line-height: 1.4;
  white-space: normal;
  overflow-wrap: break-word;
}

.prompt p, .template p {
  font-size: 16px; /* Changed font size for readability */
  color: #bbb;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  flex: 1;
}

.prompt-category, .template-category {
  font-size: 14px;
  padding: 5px 12px;
  border-radius: 5px;
  color: white;
  background-color: #333;
  margin-bottom: 10px;
  max-width: fit-content;
}

/* Buttons for prompts and templates */
.prompt-buttons, .template-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.view-prompt-button, .view-template-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 12px 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.view-prompt-button:hover, .view-template-button:hover {
  background-color: #2980b9;
  box-shadow: 0 0 15px rgba(52, 152, 219, 0.75);
}

.edit-button, .delete-button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;
  font-family: 'Lato', sans-serif;
}

.edit-button:hover, .delete-button:hover {
  color: #bbb;
}

.submit-prompt-button, .submit-template-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.submit-prompt-button:hover, .submit-template-button:hover {
  background-color: #2980b9;
}


.prompt:hover, .template:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }
  

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .main-content {
      margin-left: 0;
    }
  
    .category-filters {
      flex-direction: column;
      align-items: center;
    }
  
    .search-bar {
      width: 100%;
      margin: 10px 0;
    }
  
    /* Adjust grid layout for smaller screens */
    .popular-prompts, .popular-templates {
      grid-template-columns: repeat(1, 1fr); /* One column layout */
    }
  }
  


  