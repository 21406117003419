/* Sidebar styles */
.sidebar {
  position: fixed;
  top: 120px;
  left: 20px;
  width: 260px;
  padding: 20px;
  background-color: #1a1a1a;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  z-index: 1001;
  transform: translateZ(0);
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, left 0.3s ease-in-out;
  overflow-y: auto;
  height: auto;
}

.sidebar.open {
  transform: translateX(0);
}

/* Sidebar button styles */
.sidebar .menu-container {
  display: flex;
  flex-direction: column;
}

.sidebar .sidebar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6495ED;
  color: #ffffff;
  width: 100%;
  height: 50px;
  border-radius: 12px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.sidebar .sidebar-button:hover,
.sidebar .sidebar-button.active {
  background-color: #4169E1;
}

.sidebar .sidebar-button.gray {
  background-color: #A9A9A9;
  color: #ffffff;
}

.sidebar .sidebar-button.gray:hover {
  background-color: #808080;
}

/* Add 3D hover effect */
.sidebar .sidebar-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

/* Responsive adjustments */
@media screen and (max-width: 1024px) {
  .sidebar {
    width: 220px; /* Reduce width on medium screens */
    left: 15px;
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    width: 200px; /* Further reduce width on smaller screens */
    left: 10px;
  }

  .sidebar .sidebar-button {
    font-size: 16px; /* Adjust font size for smaller screens */
    height: 45px;
  }
}

@media screen and (max-width: 480px) {
  .sidebar {
    width: 100%; /* Full width on very small screens */
    left: 0;
    top: 0;
    border-radius: 0;
    padding: 10px;
    box-shadow: none;
  }

  .sidebar .menu-container {
    flex-direction: row; /* Horizontal menu on very small screens */
    overflow-x: auto; /* Allow horizontal scrolling */
  }

  .sidebar .sidebar-button {
    flex: 1;
    margin: 5px; /* Adjust margin for smaller buttons */
  }
}
