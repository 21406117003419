@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap');

/* General Styles for Home Page */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #1a1a1a;
  color: #ffffff;
  overflow-x: hidden;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Lato', sans-serif;
}

.home {
  padding: 20px;
  margin-left: 260px; /* Adjust for sidebar */
  margin-top: 70px; /* Adjust for header */
  transition: margin-left 0.3s ease;
}

.intro-container {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.intro-box {
  background: #20232a;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.intro-container h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #3498db;
}

.intro-container p {
  font-size: 18px;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
  color: #b0b0b0;
}

/* Prompts Styling */
.popular-prompts {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Keep 3 columns */
  gap: 20px;
  justify-items: center;
  max-width: 1200px;
  margin: 40px auto;
  padding-bottom: 40px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.popular-prompts.active {
  opacity: 1;
  transform: translateY(0);
}

.prompt {
  border-radius: 10px;
  padding: 35px;
  background: #20232a;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 350px;
}

.prompt:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.prompt h3 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.4;
}

.prompt p {
  font-size: 16px;
  color: #bbb;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  flex: 1;
}

.prompt-category {
  font-size: 14px;
  padding: 5px 12px;
  border-radius: 5px;
  color: white;
  background-color: #333;
  margin-bottom: 10px;
  max-width: fit-content;
}

.view-prompt-container .prompt-category {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}

.prompt-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.view-prompt-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 12px 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.view-prompt-button:hover {
  background-color: #2980b9;
  box-shadow: 0 0 15px rgba(52, 152, 219, 0.75);
}

/* Create Prompt Button Styling */
.submit-prompt-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
  margin-left: auto;
  margin-right: auto; /* Center the button */
}

/* View Prompt Container */
.view-prompt-container {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #20232a;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.view-prompt-container.active {
  opacity: 1;
  transform: translateY(0);
}

.view-prompt-description {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #b0b0b0;
}

.view-prompt-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Styling for Copy and Back Buttons */
.view-prompt-copy-button,
.view-prompt-back-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.view-prompt-copy-button:hover {
  background-color: #2980b9;
  box-shadow: 0 0 15px rgba(52, 152, 219, 0.75);
}

.view-prompt-back-button {
  background-color: #e74c3c;
  margin-left: 10px;
}

.view-prompt-back-button:hover {
  background-color: #c0392b;
  box-shadow: 0 0 15px rgba(231, 76, 60, 0.75);
}

/* Footer */
.footer {
  background: #282c34;
  color: white;
  padding: 20px 0;
  text-align: center;
  width: 100%;
}

/* Animations */
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.fade-in.active {
  opacity: 1;
  transform: translateY(0);
}

/* Hover Effect for Buttons */
.button-animate {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.button-animate:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

/* Prompt hover effect */
.prompt:hover, .template:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}
