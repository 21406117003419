/* src/HowToWritePrompts.css */

.how-to-write-prompts {
    padding: 20px;
    background-color: #1a1a1a;
    color: white;
    text-align: center;
    margin-top: 20px;
  }
  
  .how-to-write-prompts h2 {
    font-size: 28px;
    margin-bottom: 20px;
    position: relative;
  }
  
  .how-to-write-prompts .intro-text {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .prompt-tips {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    position: relative; /* Added for pseudo-element positioning */
  }
  
  .prompt-tip {
    background: #20232a;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    width: 300px;
    text-align: left;
  }
  
  .prompt-tip h3 {
    font-size: 20px;
    margin-bottom: 10px;
    position: relative;
  }
  
  .prompt-tip p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  /* Add a pseudo-element to create the purple line connecting the containers */
  .prompt-tips::before {
    content: '';
    position: absolute;
    top: 50%;
    left: calc(50% - 10px); /* Adjust this value based on your layout */
    width: 20px;
    height: 2px;
    background-color: #6C63FF; /* Purple color */
  }
  