/* src/Login.css */

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1a1a1a; /* Dark background color */
    color: #ffffff; /* White text color */
  }
  
  .login-container {
    text-align: center;
    background-color: #282c34; /* Dark background for container */
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow for 3D effect */
  }
  
  .login-container h1 {
    margin-bottom: 20px;
  }
  
  .auth-button {
    background-color: #3498db; /* Match the button color theme */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin-top: 20px; /* Space between welcome message and button */
  }
  
  .auth-button:hover {
    background-color: #2980b9; /* Darker blue on hover */
    box-shadow: 0 0 15px rgba(52, 152, 219, 0.75); /* Blue glow effect */
  }
  