.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #282c34; /* Match the background color */
  padding: 35px; /* Match padding with prompt boxes */
  border-radius: 10px;
  width: 400px; /* Set a fixed width similar to home form */
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow for 3D effect */
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

.prompt-form label {
  display: block;
  font-size: 16px; /* Adjust font size for labels */
  color: #fff;
  margin-bottom: 10px; /* Adjust margin for spacing */
}

.prompt-form input,
.prompt-form textarea,
.prompt-form select {
  width: 100%;
  padding: 10px; /* Adjust padding for consistency */
  margin-bottom: 20px; /* Consistent margin for spacing */
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #333; /* Darker background color */
  color: white;
  font-size: 16px; /* Consistent font size */
}

.prompt-form input::placeholder,
.prompt-form textarea::placeholder,
.prompt-form select {
  color: #999; /* Lighter placeholder text */
}

.prompt-form input:focus,
.prompt-form textarea:focus,
.prompt-form select:focus {
  outline: none;
  border-color: #7289da; /* Blue border on focus */
}

.form-submit-button {
  background-color: #3498db; /* Consistent button background */
  color: white;
  border: none;
  padding: 12px 20px; /* Consistent padding */
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px; /* Consistent font size */
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.form-submit-button:hover {
  background-color: #2980b9; /* Darker blue on hover */
}
