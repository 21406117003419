/* src/FAQ.css */

.faq-section {
    padding: 20px;
    background-color: #1a1a1a;
    color: white;
    text-align: center;
    margin-top: 20px;
  }
  
  .faq-section h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .faq-container {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
  }
  
  .faq-item {
    background: #20232a;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    transition: max-height 0.4s ease, padding 0.4s ease; /* Add transition for height and padding */
  }
  
  .faq-item.active {
    max-height: 300px; /* Adjust as needed */
    padding: 15px;
  }
  
  .faq-item:not(.active) {
    max-height: 60px; /* Height of the question part */
    padding: 15px;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #333;
  }
  
  .faq-question h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .faq-icon {
    font-size: 24px;
  }
  
  .faq-answer {
    padding-top: 10px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.4s ease; /* Smooth opacity transition */
  }
  
  .faq-item.active .faq-answer {
    opacity: 1; /* Show answer when active */
  }
  
/* Typewriter effect */
.intro-title {
  font-size: 32px;
  font-weight: bold;
  overflow: hidden;
  border-right: 0.15em solid #ffffff; /* Cursor effect */
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 3s steps(30, end), blink-caret 0.75s step-end 1;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #ffffff }
}

.intro-text {
  font-size: 18px;
  line-height: 1.6;
  opacity: 0;
  transition: opacity 2s ease-in;
  margin-top: 10px; /* Add some space after the title */
}

.intro-text.fade-in {
  opacity: 1;
}

/* Background animation */
.background-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #ff7e5f, #feb47b, #ff7e5f);
  background-size: 300% 300%;
  animation: gradientAnimation 10s ease infinite;
  z-index: -1;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50% }
  50% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
}
