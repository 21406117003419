/* src/Header.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #20232a; /* Match the background color of the app */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    position: fixed; /* Make the header fixed at the top */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure the header is above other content */
  }
  
  .header-left {
    display: flex;
    align-items: center;
  }
  
  .header-center {
    display: flex;
    align-items: center;
    margin: 0 auto; /* Center the content */
  }
  
  .header-right {
    display: flex;
    align-items: center;
  }
  
  .logo-section {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 40px;
    object-fit: contain; /* Ensure the logo scales properly */
    text-align: left;
    margin-right: 10px; /* Adjust spacing between logo and text */
  }
  
  .library-title {
    font-size: 20px;
    color: #fff;
  }
  
  .header-content {
    display: flex;
    align-items: center;
  }
  
  .menu-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 20px;
  }
  
  .menu-icon {
    width: 25px;
    height: auto;
    background: none; /* Remove background */
  }
  
  .greeting {
    margin-right: 20px;
    font-size: 16px;
    color: #fff;
  }
  
  .profile-section {
    display: flex;
    align-items: center;
  }
  
  .profile-picture {
    width: 40px; /* Adjust size as needed */
    height: 40px;
    border-radius: 50%; /* Make the image circular */
    margin-right: 10px; /* Add space between picture and logout button */
    border: 2px solid #fff; /* Optional: add a border */
  }
  
  .logout-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .logout-button:hover {
    background-color: #c0392b;
  }
  